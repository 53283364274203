import { SessionId } from './SessionId.dto'

/* eslint-disable no-extend-native */
class ChallengeImage {
  image = ''
  faceType = ''

  constructor({ image = '', faceType = '' } = {}) {
    this.image = image
    this.faceType = faceType
  }
}

Array.prototype.toChallengeImages = function toChallengeImages() {
  return this.map(challengeImage => new ChallengeImage(challengeImage))
}

class ChallengeResultRequest {
  id = new SessionId()
  images = [new ChallengeImage()]

  constructor({
    sessionId = new SessionId(),
    images = [new ChallengeImage()],
  } = {}) {
    this.id = sessionId.value
    this.images = images.toChallengeImages()
  }
}

export { ChallengeResultRequest, ChallengeImage }
