/* eslint-disable no-extend-native */
class Snap {
  amount = 0
  frequencyInMillis = 0

  constructor({ amount = 0, frequencyInMillis = 0 } = {}) {
    this.amount = amount
    this.frequencyInMillis = frequencyInMillis
  }
}

class Instruction {
  timeInSeconds = 0
  code = ''
  content = ''

  constructor({ timeInSeconds = 0, code = '', content = '' } = {}) {
    this.timeInSeconds = timeInSeconds
    this.code = code
    this.content = content
  }

  get timeInMilliseconds() {
    return this.timeInSeconds * 1000
  }

  get faceType() {
    return this.code
  }
}

Array.prototype.toInstructions = function toInstructions() {
  return this.map(instruction => new Instruction(instruction))
}

class ChallengeInstructionResponse {
  totalTime = 0
  instructions = [new Instruction()]
  snap = new Snap()

  #currentInstructionIndex = 0

  constructor({
    totalTime = 0,
    instructions = [new Instruction()],
    snap = new Snap(),
  } = {}) {
    this.totalTime = totalTime
    this.instructions = instructions.toInstructions()
    this.snap = new Snap(snap)
  }

  get nextInstruction() {
    return this.#currentInstructionIndex < this.instructions.length
      ? this.instructions[this.#currentInstructionIndex++]
      : null
  }

  get totalAmountOfSnaps() {
    return this.snap.amount * this.instructions.length
  }

  get snapsPerChallenge() {
    return this.snap.amount
  }

  get instructionDuration() {
    return this.instructions[0].timeInSeconds
  }

  get snapDuration() {
    return this.snap.frequencyInMillis
  }

  get snapTimes() {
    return Array.from(
      { length: this.totalAmountOfSnaps },
      (_, index) => (index + 1) * this.snapDuration,
    )
  }

  get instructionTimes() {
    return this.instructions.map(
      (instruction, index) => instruction.timeInMilliseconds * index,
    )
  }

  get totalTimeInMilliseconds() {
    return this.totalTime * 1000
  }

  get faceTypes() {
    return this.instructions
      .map(instruction =>
        Array.from(
          { length: this.snapsPerChallenge },
          () => instruction.faceType,
        ),
      )
      .flat()
  }
}

export { ChallengeInstructionResponse, Instruction, Snap }
