/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { bool, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StyledButton } from 'components/StyledButton'
import {
  MainContainer,
  BorderContainer,
  TitleContainer,
  SubtitleContainer,
  IconContainer,
} from 'components/Containers'
import { isWindowDefined } from 'utils/window.util'
import { FeedbackIconHappy, FeedbackIconSad } from './FeedbackIcons'
import { FeedbackContainer } from './feedback.style'

const propTypes = {
  isSuccessful: bool.isRequired,
  navigate: func,
}

const Feedback = ({ isSuccessful, navigate }) => {
  const { t } = useTranslation()
  const feedbackStates = {
    SUCCESS: {
      title: t('feedback:success:title'),
      subTitle: t('feedback:success:subTitle'),
      buttonText: t('feedback:success:buttonText'),
    },
    FAILURE: {
      title: t('feedback:failure:title'),
      subTitle: t('feedback:failure:subTitle'),
      buttonText: t('feedback:failure:buttonText'),
    },
  }

  const FeedbackState = isSuccessful
    ? feedbackStates.SUCCESS
    : feedbackStates.FAILURE

  const FeedbackIcon = isSuccessful ? FeedbackIconHappy : FeedbackIconSad

  const handleClick = () => {
    if (isWindowDefined()) {
      const sessionId = sessionStorage.getItem('sessionId')
      navigate(`/${sessionId}`, { state: { sessionId } })
    }
  }

  return (
    <MainContainer>
      <BorderContainer>
        <FeedbackContainer>
          <IconContainer>
            <FeedbackIcon />
          </IconContainer>
          <TitleContainer>{FeedbackState.title}</TitleContainer>
          <SubtitleContainer>{FeedbackState.subTitle}</SubtitleContainer>
          {!isSuccessful && (
            <StyledButton onClick={handleClick}>
              {FeedbackState.buttonText}
            </StyledButton>
          )}
        </FeedbackContainer>
      </BorderContainer>
    </MainContainer>
  )
}

Feedback.propTypes = propTypes

export { Feedback }
