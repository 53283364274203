/* eslint-disable react/jsx-no-literals */
import { Alert } from '@creditas/icons'
import { Typography } from '@creditas/typography'
import { func } from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton, TertiaryButton } from 'components/StyledButton'
import {
  Container,
  TypographyContainer,
  typographyStyle,
} from './cameraPermission.style'

const propTypes = {
  allowHandler: func.isRequired,
  denyHandler: func.isRequired,
}

const CameraPermission = ({ allowHandler, denyHandler }) => {
  const { t } = useTranslation()

  const labels = {
    cameraConsentText: t('cameraPermission:camera:access:request'),
    allowCameraText: t('cameraPermission:camera:access:allow'),
    denyCameraText: t('cameraPermission:camera:access:deny'),
  }

  return (
    <Container data-testid="modal">
      <Alert.AlertTriangle fontSize="large" color="grey" />
      <TypographyContainer>
        <Typography
          data-testid="support-text"
          css={typographyStyle}
          variant="support"
        >
          {labels.cameraConsentText}
        </Typography>
      </TypographyContainer>
      <PrimaryButton data-testid="allow-button" onClick={allowHandler}>
        {labels.allowCameraText}
      </PrimaryButton>
      <TertiaryButton data-testid="deny-button" onClick={denyHandler}>
        {labels.denyCameraText}
      </TertiaryButton>
    </Container>
  )
}

CameraPermission.propTypes = propTypes

export { CameraPermission }
