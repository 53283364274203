import { styled } from '@creditas/stylitas'

export const MessageContainer = styled.div`
  position: absolute;
  height: fit-content;
  align-self: center;
  z-index: 9999;
  font-size: 2rem;
  font-weight: bold;
  color: rgba(31, 200, 172, 1);
`
