import React, { useEffect } from 'react'
import { string, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@creditas/typography'
import { MainContainer } from 'components/Containers'
import { Header } from 'components/Layout/Header'
import { Carousel } from 'components/Carousel'
import { PrimaryButton } from 'components/StyledButton'
import { CustomFooter as Footer } from 'components/Footer'
import { SessionId } from 'src/hooks/dto/SessionId.dto'
import { ContentContainer, TitleContainer } from './guidelinesPage.style'

const propTypes = {
  sessionId: string,
  navigate: func,
}

const GuidelinesPage = ({ sessionId, navigate }) => {
  const { t } = useTranslation()

  useEffect(() => {
    window.location.replace(
      `${process.env.FACIAL_BIOMETRIC_DOMAIN_NAME_3D}/${sessionId}`,
    )
  }, [])

  const handleStart = () =>
    navigate('../biometria', { state: { sessionId: new SessionId(sessionId) } })

  return (
    <MainContainer clipHeight>
      <Header />
      <ContentContainer>
        <TitleContainer>
          <Typography>{t('guidelines:header')}</Typography>
        </TitleContainer>
        <Carousel
          contents={t('guidelines:guidelines', { returnObjects: true })}
        />
        <PrimaryButton onClick={handleStart}>
          {t('commons:phrases:start')}
        </PrimaryButton>
        <Footer />
      </ContentContainer>
    </MainContainer>
  )
}

GuidelinesPage.propTypes = propTypes

export { GuidelinesPage }
