/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { styled } from '@creditas/stylitas'

const FrameContainer = styled.span`
  position: absolute;
  z-index: 999;
  align-self: center;
`

const FrameOverlay = () => (
  <FrameContainer data-testid="frame-overlay">
    <svg
      width="295"
      height="446"
      viewBox="0 0 295 446"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 2H2V21"
        stroke="#24E28B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M274 444H293V425"
        stroke="#24E28B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M274 2H293V21"
        stroke="#24E28B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M21 444H2V425"
        stroke="#24E28B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M283 199.877C283 247.421 267.834 297.013 243.328 334.698C218.805 372.408 185.072 398 148 398C110.928 398 77.1946 372.408 52.6718 334.698C28.1656 297.013 13 247.421 13 199.877C13 152.125 16.4446 109.169 35.001 78.1479C53.4805 47.2558 87.0886 28 148 28C208.911 28 242.519 47.2558 260.999 78.1479C279.555 109.169 283 152.125 283 199.877Z"
        stroke="#49E295"
        strokeWidth="2"
      />
    </svg>
  </FrameContainer>
)

export { FrameOverlay }
