import { styled, css } from '@creditas/stylitas'
import { mq } from 'styles/MediaQuery'

const centeredFlexColumn = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  margin: 0 auto;
  max-width: 640px;
`

const minHeight = css`
  min-height: 820px;
`

export const MainContainer = styled.div`
  ${centeredFlexColumn}
  ${({ clipHeight = false }) => clipHeight && minHeight}
`

const gradientBackground = css`
  background: linear-gradient(
    90deg,
    rgba(31, 200, 172, 1) 0%,
    rgba(34, 214, 144, 1) 100%
  );
`

const roundedBorders = css`
  ${mq({ maxHeight: ['720px', '720px', '720px', '480px', '480px'] })}
  width: 100vw;
  height: 100%;
  max-width: 640px;
  border-radius: 5px;
  background-color: white;
`

const pad = css`
  padding: 2px;
`

export const BorderContainer = styled.div`
  ${roundedBorders}
  ${({ padded = false }) => padded && pad}
`

export const GradientBorderContainer = styled.div`
  ${roundedBorders}
  ${gradientBackground}
`

export const FrameContainer = styled.div`
  ${mq({ maxHeight: ['760px', '740px', '720px', '480px', '480px'] })}
  max-width: fit-content;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const overlayFlexEnd = css`
  position: absolute;
  height: fit-content;
  align-self: flex-end;
`

export const TitleContainer = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  height: fit-content;
`

export const SubtitleContainer = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #6f7974;
  margin-bottom: 20px;
  height: fit-content;
`

export const IconContainer = styled.div`
  margin-bottom: 40px;
  height: fit-content;
`
