import React from 'react'
import { string } from 'prop-types'
import { INSTRUCTION_IMAGE_PREFIX } from 'constants/camera.constants'
import { MessageContainer } from './instruction.style'

const propTypes = {
  content: string.isRequired,
}

const Instruction = ({ content }) => {
  const ALTERNATE_IMAGE_DESCRIPTION = 'instruction'
  const imageSource = `${INSTRUCTION_IMAGE_PREFIX}${content}`

  return (
    <MessageContainer>
      <img alt={ALTERNATE_IMAGE_DESCRIPTION} src={imageSource} />
    </MessageContainer>
  )
}

Instruction.propTypes = propTypes

export { Instruction }
