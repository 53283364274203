import React from 'react'
import { node } from 'prop-types'
import { isMobileOnly } from 'react-device-detect'
import {
  GradientBorderContainer,
  FrameContainer,
  MainContainer,
} from 'components/Containers'

const propTypes = {
  children: node.isRequired,
}

const CameraContainer = ({ children }) =>
  isMobileOnly ? (
    <FrameContainer>{children}</FrameContainer>
  ) : (
    <MainContainer>
      <GradientBorderContainer>
        <FrameContainer>{children}</FrameContainer>
      </GradientBorderContainer>
    </MainContainer>
  )

CameraContainer.propTypes = propTypes

export { CameraContainer }
