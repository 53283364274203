import { styled, css } from '@creditas/stylitas'
import { mq } from 'styles/MediaQuery'

export const SliderContainer = styled.div`
  ${mq({
    maxWidth: [...Array(2).fill('320px'), '480px', ...Array(3).fill('640px')],
  })}
  width: 100%;
  max-height: 500px;
  background-color: white;
  margin: 0 auto;
`

export const CardContainer = styled.div`
  padding: 50px;
`

export const Card = styled.div`
  width: 100%;
  border-radius: 20px;
  min-height: 202px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 28px 44px rgba(20, 20, 20, 0.16);
  padding: 50px 16px 0;
  margin: 0 auto;
`

export const centerText = css`
  text-align: center;
`
