import { styled, css } from '@creditas/stylitas'

export const LogoIconContainer = styled.div`
  margin-bottom: 40px;
  height: fit-content;
`

export const footerStyle = css`
  background-color: white;
  height: fit-content;
  width: 100%;
  align-self: flex-end;
  border-top: 0.5px solid #edf0ef;
`
