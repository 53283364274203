import { FeedbackIconSad } from 'components/Feedback/FeedbackIcons'
export class FacialBiometryError {
  title = ''
  message = ''
  icon = FeedbackIconSad

  constructor({
    title = 'Erro inesperado',
    message = 'Ocorreu um erro inesperado. Por favor tente novamente.',
    icon = FeedbackIconSad,
  } = {}) {
    this.title = title
    this.message = message
    this.icon = icon
  }

  map(
    callback = function mapper(
      facialBiometryError = new FacialBiometryError(),
    ) {
      return facialBiometryError
    },
  ) {
    return callback(this)
  }
}
