/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { css } from '@creditas/stylitas'
import { Button } from '@creditas/button'
import { func, node } from 'prop-types'

const style = css`
  background-color: #49e295;
  :hover {
    background-color: #11dd74;
  }
`
const stylePrimary = css`
  background-color: #49e295;
  :hover {
    background-color: #11dd74;
  }
  font-family: Helvetica Now Display;
  font-style: normal;
  font-weight: normal;
  font-size: 100%;
  color: black;
`

const styleTertiary = css`
  color: #11bb77;
  font-size: 85%;
  width: fit-content;
  min-width: auto;
  padding: 0;
  margin: 8px 16px;
`

const propTypes = {
  children: node.isRequired,
  onClick: func.isRequired,
}

const buttonPropTypes = {
  children: node.isRequired,
  onClick: func.isRequired,
}

const StyledButton = ({ children, onClick, ...rest }) => (
  <Button css={style} onClick={onClick} {...rest}>
    {children}
  </Button>
)
StyledButton.propTypes = propTypes

const PrimaryButton = ({ children, onClick, ...rest }) => (
  <Button css={stylePrimary} onClick={onClick} {...rest}>
    {children}
  </Button>
)
PrimaryButton.propTypes = buttonPropTypes

const TertiaryButton = ({ children, onClick, ...rest }) => (
  <Button variant="tertiary" css={styleTertiary} onClick={onClick} {...rest}>
    {children}
  </Button>
)
TertiaryButton.propTypes = buttonPropTypes

export { StyledButton, PrimaryButton, TertiaryButton }
