const IMAGE_FORMAT = 'image/jpeg'

const INSTRUCTION_IMAGE_PREFIX = 'data:image/png;base64,'

const IMAGE_SIZE = {
  width: 320,
  height: 480,
  aspectRatio: {
    landscape: 480 / 320,
    portrait: 320 / 480,
  },
}

const SCALING_FACTOR = 1.5

const VIDEO_CONSTRAINTS = {
  browser: {
    width: { exact: 640 },
    height: { exact: 480 },
    facingMode: 'user',
  },
  mobile: {
    width: { exact: 1280 },
    height: { exact: 720 },
    facingMode: 'user',
  },
}

export {
  IMAGE_FORMAT,
  IMAGE_SIZE,
  SCALING_FACTOR,
  VIDEO_CONSTRAINTS,
  INSTRUCTION_IMAGE_PREFIX,
}
