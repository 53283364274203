/* eslint-disable react/jsx-no-literals */
import { Camera, CameraContainer, FrameOverlay } from 'components/Camera'
import { func, string, shape } from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ChallengeImage,
  ChallengeResultRequest,
} from 'hooks/dto/ChallengeResultRequest.dto'
import { useImageCrop } from 'hooks/useImageCrop'
import { useTrust } from 'hooks/useTrust'
import { zip } from 'utils/array.utils'
import { Spinner } from 'components/Spinner'
import { PrimaryButton } from 'components/StyledButton'
import { SessionId } from 'src/hooks/dto/SessionId.dto'
import { ButtonContainer } from './facialBiometry.style'
import { Instruction } from './Instruction'

const propTypes = {
  sessionId: shape({
    value: string.isRequired,
  }).isRequired,
  navigate: func,
}

const FacialBiometry = ({ sessionId = new SessionId(), navigate }) => {
  const { t } = useTranslation()
  const cameraRef = useRef(null)
  const { cropImage } = useImageCrop()
  const [challengeImages, setChallengeImages] = useState([])
  const [currentInstruction, setCurrentInstruction] = useState(null)
  const [isDone, setIsDone] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {
    fetchChallengeInstructions,
    submitChallengeImages,
    instructions,
    result,
    error,
  } = useTrust()

  const handleStart = () => {
    setIsLoading(true)
    fetchChallengeInstructions({ sessionId })
  }

  const captureImage = useCallback(() => {
    const newImage = cropImage(cameraRef.current.video)
    setChallengeImages(previousImages => [...previousImages, newImage])
  }, [cropImage])

  useEffect(() => {
    if (instructions) {
      setTimeout(setIsDone, instructions.totalTimeInMilliseconds, true)

      instructions.instructionTimes.forEach(instructionTime =>
        setTimeout(
          setCurrentInstruction,
          instructionTime,
          instructions.nextInstruction,
        ),
      )

      instructions.snapTimes.forEach(snapTime =>
        setTimeout(captureImage, snapTime),
      )
    }
  }, [instructions])

  useEffect(() => {
    if (isDone) {
      const request = new ChallengeResultRequest({
        sessionId,
        images: zip(challengeImages, instructions.faceTypes).map(
          ([image, faceType]) => new ChallengeImage({ image, faceType }),
        ),
      })

      submitChallengeImages(request)
    }
  }, [isDone])

  useEffect(() => {
    if (result)
      navigate('../resultado', {
        state: { isSuccess: result.isSuccess || result.isLastAttempt },
      })
  }, [result])

  useEffect(() => {
    if (error != null)
      navigate('../resultado', {
        state: { error: error.response.data },
      })
  }, [error])

  return (
    <CameraContainer>
      <Camera cameraRef={cameraRef} />
      <FrameOverlay />
      {isDone && <Spinner />}
      {!isDone && currentInstruction && (
        <Instruction content={currentInstruction.content} />
      )}
      {!instructions && (
        <ButtonContainer>
          <PrimaryButton
            data-testid="start-button"
            onClick={handleStart}
            isLoading={isLoading}
          >
            {t('commons:phrases:start')}
          </PrimaryButton>
        </ButtonContainer>
      )}
    </CameraContainer>
  )
}

FacialBiometry.propTypes = propTypes

export { FacialBiometry }
