export class SessionId {
  value = ''
  static IV_SIZE = 16
  static KEY_SIZE = 16

  constructor(value = '') {
    this.value = value
  }

  get key() {
    return this.value.slice(0, SessionId.KEY_SIZE)
  }

  get iv() {
    return this.value
      .split('')
      .reverse()
      .join('')
      .slice(0, SessionId.IV_SIZE)
  }
}
