/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { arrayOf, string } from 'prop-types'
import Slider from 'react-slick'
import { Typography } from '@creditas/typography'
import {
  SliderContainer,
  Card,
  CardContainer,
  centerText,
} from './carousel.style'
import './style.css'

const propTypes = {
  contents: arrayOf(string.isRequired).isRequired,
}

const Carousel = ({ contents }) => (
  <SliderContainer>
    <Slider dots infinite arrows={false}>
      {contents.map((content, index) => (
        <CardContainer key={index}>
          <Card>
            <Typography css={centerText} variant="support">
              {content}
            </Typography>
          </Card>
        </CardContainer>
      ))}
    </Slider>
  </SliderContainer>
)

Carousel.propTypes = propTypes

export { Carousel }
