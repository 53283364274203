import { styled } from '@creditas/stylitas'

export const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
`
