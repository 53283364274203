/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { object } from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  MainContainer,
  BorderContainer,
  TitleContainer,
  SubtitleContainer,
  IconContainer,
} from 'components/Containers'
import {
  FeedbackIconHappy,
  FeedbackIconSad,
} from 'components/Feedback/FeedbackIcons'
import { ContentContainer } from './errorAlert.style'
import { FacialBiometryError, NoResultError } from './dto'

const propTypes = {
  error: object,
}

const defaultError = {
  details: [{ target: 'no-result' }],
}

const ErrorAlert = ({ error = defaultError }) => {
  const { t } = useTranslation()
  const errorHandler = ({
    details: [{ target = 'default' } = {}] = [],
  } = {}) => {
    switch (target) {
      case 'facial-biometric-session-expired': {
        return new FacialBiometryError({
          title: t('feedback:session-expired:title'),
          message: t('feedback:session-expired:subTitle'),
          icon: FeedbackIconSad,
        })
      }
      case 'facial-biometric-session-consumed': {
        return new FacialBiometryError({
          title: t('feedback:success:title'),
          message: t('feedback:success:subTitle'),
          icon: FeedbackIconHappy,
        })
      }
      case 'no-result': {
        return new NoResultError({
          title: t('feedback:no-result:title'),
          message: t('feedback:no-result:subTitle'),
          icon: FeedbackIconSad,
        })
      }
      default: {
        return new FacialBiometryError()
      }
    }
  }

  return (
    <MainContainer>
      <BorderContainer>
        <ContentContainer>
          {errorHandler(error).map(({ title, message, icon: Icon }) => (
            <>
              <IconContainer>
                <Icon />
              </IconContainer>
              <TitleContainer>{title}</TitleContainer>
              <SubtitleContainer>{message}</SubtitleContainer>
            </>
          ))}
        </ContentContainer>
      </BorderContainer>
    </MainContainer>
  )
}

ErrorAlert.propTypes = propTypes

export { ErrorAlert }
