import React from 'react'
import { any, func } from 'prop-types'
import { Feedback } from 'components/Feedback'
import { ErrorAlert } from 'components/ErrorAlert'
import { CustomFooter } from 'components/Footer'
import { MainContainer } from 'components/Containers'
import { ContentContainer } from './resultPage.style'

const propTypes = {
  location: any,
  navigate: func,
}

const ResultPage = ({ location, navigate }) => (
  <MainContainer>
    <ContentContainer>
      {location.state && location.state.isSuccess != null ? (
        <Feedback navigate={navigate} isSuccessful={location.state.isSuccess} />
      ) : (
        <ErrorAlert error={location.state?.error} />
      )}
      <CustomFooter />
    </ContentContainer>
  </MainContainer>
)

ResultPage.propTypes = propTypes

export { ResultPage }
