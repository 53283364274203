/* eslint-disable react/jsx-no-literals */
import React from 'react'

const FeedbackIconSad = () => (
  <svg
    width="87"
    height="72"
    viewBox="0 0 87 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="55" cy="37" r="32" fill="#E72222" fillOpacity="0.26" />
    <path
      d="M63 36C63 21.114 50.886 9 36 9C21.111 9 9 21.111 9 36"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36 63C50.889 63 63 50.886 63 36"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 36C9 50.889 21.111 63 36 63"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.9833 28.5606C45.8351 28.5611 45.6904 28.6056 45.5675 28.6884C45.4445 28.7712 45.3489 28.8885 45.2926 29.0256C45.2363 29.1627 45.2218 29.3134 45.2511 29.4587C45.2804 29.604 45.3521 29.7373 45.4571 29.8419C45.5621 29.9465 45.6957 30.0177 45.8411 30.0464C45.9865 30.0751 46.1372 30.06 46.274 30.0032C46.4109 29.9463 46.5279 29.8502 46.6101 29.7269C46.6924 29.6036 46.7363 29.4588 46.7363 29.3106C46.7363 29.2118 46.7168 29.114 46.679 29.0228C46.6411 28.9317 46.5856 28.8488 46.5156 28.7792C46.4456 28.7095 46.3626 28.6543 46.2713 28.6168C46.1799 28.5793 46.0821 28.5602 45.9833 28.5606"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.9169 28.5C25.7687 28.5006 25.624 28.5451 25.5011 28.6279C25.3781 28.7106 25.2825 28.828 25.2262 28.9651C25.1698 29.1022 25.1554 29.2529 25.1847 29.3982C25.214 29.5434 25.2856 29.6768 25.3907 29.7814C25.4957 29.886 25.6293 29.9571 25.7747 29.9858C25.9201 30.0145 26.0708 29.9995 26.2076 29.9426C26.3445 29.8858 26.4615 29.7896 26.5437 29.6664C26.626 29.5431 26.6699 29.3982 26.6699 29.25C26.6699 29.1513 26.6504 29.0535 26.6125 28.9623C26.5747 28.8711 26.5192 28.7883 26.4492 28.7186C26.3792 28.6489 26.2962 28.5937 26.2049 28.5562C26.1135 28.5187 26.0157 28.4996 25.9169 28.5"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.5 48.0005C25.5 48.0005 29.439 44.0645 36 44.0645C42.564 44.0645 46.5 48.0005 46.5 48.0005"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export { FeedbackIconSad }
