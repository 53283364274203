import { styled, css } from '@creditas/stylitas'

export const spinnerStyle = css`
  p {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    user-select: none;
  }
`

export const SpinnerContainer = styled.div`
  position: absolute;
  z-index: 9999;
  height: fit-content;
`
