/* eslint-disable react/jsx-no-literals */
import React from 'react'

const LogoIcon = () => (
  <svg
    width="43"
    height="33"
    viewBox="0 0 43 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#1B7"
      fillRule="evenodd"
      d="M42.667 21.34c0 .428-.137.753-.137.753-2.241 6.162-8.106 10.3-14.605 10.3-2.2 0-4.294-.466-6.192-1.303 0 0-2.684 1.303-6.18 1.303C6.963 32.393 0 25.353 0 16.669 0 7.986 6.963.946 15.553.946c3.573 0 6.18 1.303 6.18 1.303A15.321 15.321 0 0127.925.945c6.499 0 12.364 4.138 14.605 10.3 0 0 .137.326.137.754 0 .297-.067.645-.291.968-.378.542-1.037.876-1.788.88h-10.15c-1.688 0-2.384-1.971-2.384-1.971A13.484 13.484 0 0021.736 4.7a13.542 13.542 0 00-7.177 11.968c.001 5.193 2.916 9.708 7.177 11.969a13.483 13.483 0 006.318-7.175s.696-1.972 2.384-1.972h10.15c.75.005 1.41.339 1.788.881.224.323.29.67.29.968zM30.049 10.577a15.794 15.794 0 00-5.668-6.984 13.298 13.298 0 013.665-.516c5.493 0 10.462 3.41 12.5 8.529h-9.368c-.735 0-1.129-1.029-1.129-1.029zM2.12 16.536C2.122 9.115 8.105 3.077 15.46 3.077c1.243 0 2.46.178 3.62.51a15.706 15.706 0 00-6.8 12.949 15.707 15.707 0 006.8 12.949c-1.16.332-2.377.51-3.62.51-7.355 0-13.338-6.038-13.34-13.459zm22.26 13.21a15.795 15.795 0 005.669-6.985s.394-1.028 1.129-1.028h9.369c-2.039 5.118-7.008 8.529-12.501 8.529-1.27 0-2.5-.183-3.665-.517z"
      clipRule="evenodd"
    />
    <mask width="43" height="33" x="0" y="0" maskUnits="userSpaceOnUse">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M42.667 21.34c0 .428-.137.753-.137.753-2.241 6.162-8.106 10.3-14.605 10.3-2.2 0-4.294-.466-6.192-1.303 0 0-2.684 1.303-6.18 1.303C6.963 32.393 0 25.353 0 16.669 0 7.986 6.963.946 15.553.946c3.573 0 6.18 1.303 6.18 1.303A15.321 15.321 0 0127.925.945c6.499 0 12.364 4.138 14.605 10.3 0 0 .137.326.137.754 0 .297-.067.645-.291.968-.378.542-1.037.876-1.788.88h-10.15c-1.688 0-2.384-1.971-2.384-1.971A13.484 13.484 0 0021.736 4.7a13.542 13.542 0 00-7.177 11.968c.001 5.193 2.916 9.708 7.177 11.969a13.483 13.483 0 006.318-7.175s.696-1.972 2.384-1.972h10.15c.75.005 1.41.339 1.788.881.224.323.29.67.29.968zM30.049 10.577a15.794 15.794 0 00-5.668-6.984 13.298 13.298 0 013.665-.516c5.493 0 10.462 3.41 12.5 8.529h-9.368c-.735 0-1.129-1.029-1.129-1.029zM2.12 16.536C2.122 9.115 8.105 3.077 15.46 3.077c1.243 0 2.46.178 3.62.51a15.706 15.706 0 00-6.8 12.949 15.707 15.707 0 006.8 12.949c-1.16.332-2.377.51-3.62.51-7.355 0-13.338-6.038-13.34-13.459zm22.26 13.21a15.795 15.795 0 005.669-6.985s.394-1.028 1.129-1.028h9.369c-2.039 5.118-7.008 8.529-12.501 8.529-1.27 0-2.5-.183-3.665-.517z"
        clipRule="evenodd"
      />
    </mask>
  </svg>
)

export { LogoIcon }
