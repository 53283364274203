import { useState } from 'react'
import axios from 'axios'
import { ChallengeInstructionResponse } from './dto/ChallengeInstructionsResponse.dto'
import { ChallengeResultRequest } from './dto/ChallengeResultRequest.dto'
import { ChallengeResultResponse } from './dto/ChallengeResultResponse.dto'
import { SessionId } from './dto/SessionId.dto'

export const useTrust = () => {
  const [instructions, setInstructions] = useState(null)
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)

  async function fetchChallengeInstructions({ sessionId = new SessionId() }) {
    try {
      const response = await axios.request({
        baseURL: process.env.FACIAL_BIOMETRIC_DOMAIN_NAME,
        url: `/challenge/instructions/${sessionId.value}`,
        headers: {
          Accept: 'application/vnd.creditas.v1+json',
        },
      })

      setInstructions(() => new ChallengeInstructionResponse(response.data))
    } catch (exception) {
      setError(() => exception)
    }
  }

  async function submitChallengeImages(
    challengeResultRequest = new ChallengeResultRequest(),
  ) {
    try {
      const response = await axios.request({
        method: 'POST',
        baseURL: process.env.FACIAL_BIOMETRIC_DOMAIN_NAME,
        url: '/challenge/submit',
        headers: {
          Accept: 'text/plain',
          'Content-Type': 'application/vnd.creditas.v1+json',
        },
        data: JSON.stringify(challengeResultRequest),
      })

      setResult(
        () =>
          new ChallengeResultResponse({
            encryptedData: response.data,
            sessionId: new SessionId(challengeResultRequest.id),
          }),
      )
    } catch (exception) {
      setError(() => exception)
    }
  }

  return {
    fetchChallengeInstructions,
    submitChallengeImages,
    instructions,
    result,
    error,
  }
}
