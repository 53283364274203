/* eslint-disable react/jsx-no-literals */
import React from 'react'

const FeedbackIconHappy = () => (
  <svg
    width="81"
    height="80"
    viewBox="0 0 81 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      opacity="0.25"
      cx="49.3346"
      cy="40.0006"
      rx="30.6667"
      ry="30.6667"
      fill="#49E295"
    />
    <path
      d="M28.334 30V33.3333"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.666 30V33.3333"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.6673 48.9609C48.4349 51.781 44.2903 53.3348 40.0007 53.3348C35.711 53.3348 31.5664 51.781 28.334 48.9609"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.6067 31.1767C69.5169 34.0299 69.9867 37.0052 70 40C70 45.9334 68.2405 51.7336 64.9441 56.6671C61.6477 61.6006 56.9623 65.4458 51.4805 67.7164C45.9987 69.987 39.9667 70.5811 34.1473 69.4236C28.3279 68.266 22.9824 65.4088 18.7868 61.2132C14.5912 57.0176 11.734 51.6721 10.5765 45.8527C9.4189 40.0333 10.013 34.0013 12.2836 28.5195C14.5543 23.0377 18.3994 18.3524 23.3329 15.0559C28.2664 11.7595 34.0666 10 40 10C42.9948 10.0133 45.9701 10.4832 48.8233 11.3933"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.9997 14.25L63.3764 20.8733L59.123 16.6267"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export { FeedbackIconHappy }
