import React, { useState } from 'react'
import { any, func } from 'prop-types'
import { FacialBiometry } from 'components/FacialBiometry'
import { CameraPermission } from 'components/CameraPermission'
import { MainContainer, BorderContainer } from 'components/Containers'

const propTypes = {
  location: any,
  navigate: func,
}

const BiometryPage = ({ location, navigate }) => {
  const [showModal, setShowModal] = useState(true)
  const [cameraAllow, setCameraAllow] = useState(false)

  const denyCamera = () => {
    setShowModal(false)
  }
  const allowCamera = () => {
    setShowModal(false)
    setCameraAllow(true)
  }

  const sessionId = location.state ? location.state.sessionId : ''

  return (
    <MainContainer>
      <BorderContainer>
        {cameraAllow && (
          <FacialBiometry sessionId={sessionId} navigate={navigate} />
        )}
        {showModal && (
          <CameraPermission
            allowHandler={allowCamera}
            denyHandler={denyCamera}
          />
        )}
      </BorderContainer>
    </MainContainer>
  )
}

BiometryPage.propTypes = propTypes

export { BiometryPage }
