/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { Router } from '@reach/router'
import { Layout } from 'components/Layout'
import 'styles/body.css'
import { BiometryPage } from 'components/BiometryPage'
import { ResultPage } from 'components/ResultPage'
import { GuidelinesPage } from 'src/components/GuidelinesPage'

const IndexPage = () => (
  <Layout hideFooter hideHeader>
    <Router style={{ width: '100%', height: '100%' }}>
      <GuidelinesPage path="/:sessionId" />
      <BiometryPage path="/biometria" />
      <ResultPage path="/resultado" />
    </Router>
  </Layout>
)

export default IndexPage
