/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner as SpinnerCreditas } from '@creditas/spinner'
import { SpinnerContainer, spinnerStyle } from './spinner.style'

const Spinner = () => {
  const { t } = useTranslation()
  return (
    <SpinnerContainer>
      <SpinnerCreditas
        data-testid="spinner"
        message={t('commons:phrases:loading')}
        css={spinnerStyle}
      />
    </SpinnerContainer>
  )
}

export { Spinner }
