export const zip = (...arrays) => {
  const maxLength = Math.max(...arrays.map(arr => arr.length))

  const outArray = []

  for (let i = 0; i < maxLength; i++) {
    outArray.push(arrays.map(arr => arr[i]))
  }

  return outArray
}
