import { FeedbackIconSad } from 'components/Feedback/FeedbackIcons'

export class NoResultError {
  title = ''
  message = ''
  icon = FeedbackIconSad

  constructor({
    title = 'Nenhum resultado',
    message = 'Não há resultado a ser exibido. Certifique-se de acessar a página de biometria fornecida via SMS.',
    icon = FeedbackIconSad,
  } = {}) {
    this.title = title
    this.message = message
    this.icon = icon
  }

  map(
    callback = function mapper(noResultError = new NoResultError()) {
      return noResultError
    },
  ) {
    return callback(this)
  }
}
