import { styled, mq, css } from '@creditas/stylitas'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 20px;
  z-index: 999;
  background-color: white;
  border-radius: 20px 20px 0 0;
  position: absolute;
  ${mq({ maxHeight: ['720px', '720px', '720px', '480px', '480px'] })}
  width: 100vw;
  max-width: 640px;
`

export const typographyStyle = css`
  text-align: center;
`

export const TypographyContainer = styled.div`
  height: fit-content;
  margin-bottom: 15px;
`
