/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable consistent-return */
import React from 'react'
import { any } from 'prop-types'
import Webcam from 'react-webcam'
import { isMobileOnly } from 'react-device-detect'
import { IMAGE_FORMAT, VIDEO_CONSTRAINTS } from 'constants/camera.constants'

const propTypes = {
  cameraRef: any,
}

const Camera = ({ cameraRef = null }) => {
  const videoConstraints = isMobileOnly
    ? VIDEO_CONSTRAINTS.mobile
    : VIDEO_CONSTRAINTS.browser

  return (
    <Webcam
      data-testid="camera"
      style={{ height: '100%' }}
      audio={false}
      ref={cameraRef}
      mirrored
      allowFullScreen={false}
      controls={false}
      screenshotFormat={IMAGE_FORMAT}
      videoConstraints={videoConstraints}
    />
  )
}

Camera.propTypes = propTypes

export { Camera }
