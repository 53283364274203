/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Footer, FooterSection } from '@creditas/web-footer'
import { LogoIconContainer, footerStyle } from './footer.style'
import { LogoIcon, SecureIcon } from './FooterIcons'

const CustomFooter = () => {
  const { t } = useTranslation()

  const labels = {
    copyright: `© ${new Date().getFullYear()} ${t('footer:copyright')}`,
  }

  return (
    <Footer css={footerStyle}>
      <FooterSection
        columns="1fr"
        container
        rows="repeat(3,40px)"
        alignItems
        spacing="20px"
        gridTemplate={{
          xs: ` "logo"
                "copyright"
                "security" `,
          md: `
                    "logo"
                    "copyright"
                    "security"
                `,
        }}
      >
        <FooterSection container item="logo" columns="1fr">
          <LogoIconContainer>
            <LogoIcon />
          </LogoIconContainer>
        </FooterSection>
        <FooterSection container item="copyright" columns="1fr">
          {labels.copyright}
        </FooterSection>
        <FooterSection container item="security" columns="1fr">
          <LogoIconContainer>
            <SecureIcon />
          </LogoIconContainer>
        </FooterSection>
      </FooterSection>
    </Footer>
  )
}

export { CustomFooter }
