import { styled } from '@creditas/stylitas'

export const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-template-columns: 1fr;
  row-gap: 40px;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
`

export const TitleContainer = styled.div`
  padding: 40px 24px 0;
`
