import {
  IMAGE_FORMAT,
  IMAGE_SIZE,
  SCALING_FACTOR,
} from 'constants/camera.constants'
import { isWindowDefined } from 'utils/window.util'

export const useImageCrop = () => {
  function getCroppingParameters(
    video,
    aspectRatio = { landscape: 1.5, portrait: 0.67 },
    scalingFactor = 1.5,
  ) {
    class CropFrom {
      startWidth = 0
      startHeight = 0
      startX = 0
      startY = 0

      constructor(width, height, x, y) {
        this.startWidth = width
        this.startHeight = height
        this.startX = x
        this.startY = y
      }
    }

    const { videoWidth, videoHeight } = video
    const videoAspectRatio = videoWidth / videoHeight

    if (videoAspectRatio < aspectRatio.portrait)
      return new CropFrom(
        videoWidth,
        videoWidth * scalingFactor,
        0,
        (videoHeight - videoWidth * scalingFactor) / 2,
      )

    return new CropFrom(
      videoHeight / scalingFactor,
      videoHeight,
      (videoWidth - videoHeight / scalingFactor) / 2,
      0,
    )
  }

  function cropImage(imageSource) {
    if (isWindowDefined() == false) return

    const canvas = document.createElement('canvas')
    const canvasContext = canvas.getContext('2d')

    canvasContext.canvas.width = IMAGE_SIZE.width
    canvasContext.canvas.height = IMAGE_SIZE.height

    const { startX, startY, startWidth, startHeight } = getCroppingParameters(
      imageSource,
      IMAGE_SIZE.aspectRatio,
      SCALING_FACTOR,
    )

    canvasContext.drawImage(
      imageSource,
      startX,
      startY,
      startWidth,
      startHeight,
      0,
      0,
      canvasContext.canvas.width,
      canvasContext.canvas.height,
    )

    // eslint-disable-next-line consistent-return
    return canvas.toDataURL(IMAGE_FORMAT)
  }

  return { cropImage }
}
