import { AES, enc } from 'crypto-js'
import { SessionId } from './SessionId.dto'

class Status {
  static SUCCESS = 'SUCCESS'
  static FAIL = 'FAIL'
  static BLOCKED_USER = 'BLOCKED_USER'
}

class Reason {
  static BIOMETRY = 'Failed due to biometric processing'
  static CHALLENGES = 'Failed to complete one or several challenges'
}

class ChallengeResultResponse {
  status = Status.FAIL
  reason = Reason.BIOMETRY
  similarityScore = null
  attempt = 1
  maxAttempts = 1

  constructor({ encryptedData = '', sessionId = new SessionId() } = {}) {
    const {
      status,
      reason,
      similarityScore,
      attempt,
      maxAttempts,
    } = JSON.parse(
      AES.decrypt(encryptedData, enc.Latin1.parse(sessionId.key), {
        iv: enc.Latin1.parse(sessionId.iv),
      }).toString(enc.Utf8),
    )

    this.status = status
    this.reason = reason
    this.similarityScore = similarityScore
    this.attempt = attempt
    this.maxAttempts = maxAttempts
  }

  get isSuccess() {
    return this.status == Status.SUCCESS
  }

  get isLastAttempt() {
    return this.attempt == this.maxAttempts
  }
}

export { ChallengeResultResponse, Status, Reason }
