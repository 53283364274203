/* eslint-disable react/jsx-no-literals */
import React from 'react'

const SecureIcon = () => (
  <svg
    width="59"
    height="34"
    fill="none"
    viewBox="0 0 59 34"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#79C400"
      stroke="#78C402"
      d="M50.22 12.258H19.178a8.278 8.278 0 000 16.557H50.22a8.278 8.278 0 000-16.557z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M19.797 18.672h29.802a4.14 4.14 0 110 8.278H19.797v-8.278z"
      clipRule="evenodd"
    />
    <path
      stroke="#E4EFED"
      strokeWidth="4"
      d="M19.603 15.83V9.827c-.964-4.587-3.42-6.88-7.366-6.88-3.946 0-6.393 2.147-7.339 6.442v5.53"
    />
    <path
      fill="#79C400"
      stroke="#F2F8F6"
      strokeWidth="4"
      d="M12.348 31.505c5.715 0 10.348-4.633 10.348-10.348 0-5.716-4.633-10.348-10.348-10.348C6.633 10.809 2 15.442 2 21.157c0 5.715 4.633 10.348 10.348 10.348z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M6.965 19.848l.743 4.88h1.767v-4.88h-2.51zm2.82.503v4.195c.54-.063.934-.063 1.18 0 .37.095 2.142.736 3.274.736 1.132 0 2.005-.37 2.041-.736.025-.234-.01-.47-.104-.685.472-.028.687-.19.646-.485-.041-.295-.135-.482-.28-.56.486-.442.705-.785.654-1.03a1.16 1.16 0 00-.374-.637c.515-.973.421-1.506-.28-1.597-1.054-.137-2.696.18-3.048-.162-.351-.342 1.205-2.985.117-3.55-1.088-.566-1.85 2.87-2.426 3.55-.37.443-.854.775-1.4.96v.001z"
      clipRule="evenodd"
    />
    <path
      fill="#626262"
      d="M27 23.89h.894c.045.417.216.782.678.782.44 0 .604-.275.604-.655 0-.41-.202-.656-.835-.954-.864-.41-1.207-.775-1.207-1.527 0-.805.522-1.356 1.43-1.356 1.14 0 1.416.767 1.431 1.423h-.887c-.022-.29-.096-.716-.558-.716-.358 0-.514.232-.514.574 0 .365.163.537.752.812.902.418 1.311.82 1.311 1.662 0 .797-.491 1.453-1.557 1.453-1.162 0-1.512-.745-1.542-1.498zm6.348-1.617v.783h-1.624v1.467h1.818l-.112.775h-2.593v-5.044h2.585v.79h-1.698v1.23h1.624zm2.95 1.558h.894c-.022.253-.119 1.557-1.512 1.557-1.438 0-1.602-1.26-1.602-2.616 0-1.631.477-2.592 1.654-2.592 1.334 0 1.438 1.147 1.46 1.55h-.894c-.015-.172-.022-.79-.58-.79-.567 0-.709.692-.709 1.847 0 .954.097 1.848.686 1.848.536 0 .58-.61.603-.805zm1.58-3.577h.901v3.472c0 .41.105.902.663.902.537 0 .649-.41.649-.894v-3.48h.901v3.494c0 .902-.35 1.64-1.564 1.64-1.2 0-1.55-.693-1.55-1.64v-3.494zm5.312 2.891h-.387v2.153h-.894v-5.044h1.542c.916 0 1.438.484 1.438 1.378 0 .73-.395 1.044-.656 1.148.388.186.574.514.574 1.26v.2c0 .574.03.79.067 1.058h-.872c-.074-.23-.082-.633-.082-1.065v-.179c0-.678-.156-.909-.73-.909zm-.387-2.16v1.43h.424c.544 0 .76-.231.76-.738 0-.44-.208-.693-.67-.693h-.514zm5.394 1.288v.783h-1.624v1.467h1.818l-.112.775h-2.593v-5.044h2.585v.79h-1.698v1.23h1.624z"
    />
    <path
      fill="#fff"
      d="M30.889 14.535c-.569-.029-.852.293-.932.82-.016.11-.033.22-.045.33-.02.192-.057.384-.048.58.013.27.134.433.39.502.143.039.291.045.44.04.483-.021.749-.254.841-.74.022-.113.036-.226.05-.34.028-.222.066-.444.05-.67-.019-.278-.163-.448-.428-.503-.097-.021-.195-.015-.318-.02zm.334-.628c.17.03.345.043.511.097.181.058.35.136.5.258.253.208.369.487.37.811a5.093 5.093 0 01-.135 1.148c-.124.545-.46.91-.983 1.07a2.955 2.955 0 01-1.767.01 1.4 1.4 0 01-.346-.17c-.345-.223-.488-.556-.474-.959.012-.325.054-.65.118-.968.136-.69.57-1.106 1.274-1.24.126-.023.253-.038.38-.057h.552zm8.938.628c-.612-.024-.874.286-.96.777-.05.285-.085.571-.106.86-.027.344.157.569.48.613.165.023.33.029.496.009.336-.04.566-.219.663-.542.112-.377.158-.77.155-1.163 0-.319-.158-.48-.47-.541-.085-.017-.172-.008-.258-.013zm.32-.629c.14.025.28.044.42.076.206.047.395.134.566.262.23.172.37.406.385.69a3.936 3.936 0 01-.19 1.498c-.144.426-.456.699-.87.839a2.97 2.97 0 01-1.804.042c-.605-.174-.895-.586-.86-1.222.017-.308.057-.616.12-.918.148-.697.6-1.073 1.271-1.208.127-.025.256-.04.384-.058h.577zm8.003 2.901h.06c.505-.009.78-.239.878-.74.021-.106.037-.213.048-.32.025-.243.083-.482.051-.73-.032-.248-.174-.412-.418-.46a1.748 1.748 0 00-.535-.012c-.38.04-.601.27-.701.632-.034.124-.05.25-.065.376-.026.227-.073.451-.068.681.006.305.14.473.434.541.103.024.21.033.316.032zm.62-2.9c.147.026.297.036.441.075.265.07.507.183.697.389.233.252.286.563.258.89a8.746 8.746 0 01-.11.859c-.12.648-.511 1.048-1.138 1.207-.51.129-1.025.14-1.537.013a1.338 1.338 0 01-.74-.447.891.891 0 01-.203-.526 4.414 4.414 0 01.147-1.317c.145-.561.528-.891 1.07-1.046.183-.051.372-.067.558-.098h.558z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M25.18 16.047c.03-.222.055-.446.09-.667.132-.802.566-1.228 1.366-1.355.234-.037.468-.041.704-.04h1.18c.116 0 .127.014.092.122-.1.312-.3.524-.689.514-.315-.007-.631-.02-.947.016-.381.044-.615.241-.701.617-.07.304-.114.615-.12.927-.006.313.164.493.477.528.382.043.766.012 1.148.021.142.003.283 0 .424 0 .066 0 .089.023.073.093a.659.659 0 01-.66.54c-.367.002-.733.008-1.1-.002-.379-.01-.727-.119-1.018-.376-.193-.17-.277-.39-.32-.635v-.303zm11.316-.567l-.372.466-.54.683c-.208.267-.41.265-.569-.047-.172-.342-.355-.68-.533-1.018l-.068-.125-.065.507c-.04.311-.071.623-.119.932a.555.555 0 01-.563.483c-.133.003-.267 0-.4 0-.065.001-.089-.025-.08-.092.09-.72.178-1.44.267-2.162.04-.33.083-.66.12-.99.008-.075.036-.109.113-.104.175.01.352-.015.528.02.152.03.267.105.35.24.26.424.526.844.79 1.265.067.108.067.107.147.015.395-.448.788-.896 1.185-1.342.126-.142.296-.186.477-.196.133-.005.267.005.4-.003.092-.005.117.027.106.114-.038.285-.072.57-.108.853l-.212 1.706c-.008.066-.014.132-.026.198a.569.569 0 01-.56.478c-.12.004-.24-.004-.36.002-.093.005-.114-.034-.103-.119.045-.35.088-.7.13-1.05l.087-.703-.022-.011zm7.452-1.466c.326.008.71-.022 1.091.04.282.046.555.118.786.298a.943.943 0 01.37.724c.017.295-.04.584-.071.875-.021.193-.056.383-.13.563-.184.44-.533.669-.981.77-.225.05-.454.075-.684.076-.397.004-.793.003-1.19.002a1.005 1.005 0 01-.306-.043c-.195-.063-.27-.176-.246-.378.025-.211.057-.422.083-.634.052-.41.105-.82.152-1.229.01-.086.037-.128.128-.122.156.01.314-.015.47.014a.418.418 0 01.347.464 57.75 57.75 0 01-.146 1.19c-.011.086.01.116.096.112.22-.01.44.013.659-.015.485-.063.708-.291.768-.783.023-.185.046-.37.073-.555.063-.438-.167-.724-.61-.738-.322-.011-.646-.005-.97-.006-.063 0-.127.001-.189-.001-.338-.013-.479-.184-.483-.542-.001-.08.048-.082.106-.082h.877z"
      clipRule="evenodd"
    />
  </svg>
)

export { SecureIcon }
